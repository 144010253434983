import React, { useState, useEffect } from "react";
import { MdDelete } from "react-icons/md";
//import { useNavigate } from "react-router-dom";
import { useAuth } from "../services/AuthContext"; // Importe o contexto de autenticação
import "../css/Style.css";
import api from "../services/Api";
import ModalForm from "../services/modal/ModalForm";
import ModalConfirmar from "../services/modal/ModalConfirma";
import Messages from "../../config/Messages";


const Cadastros = () => {
  const [entidades, setEntidades] = useState([]);
  const [secretarias, setSecretarias] = useState([]);
  const [filteredEntidades, setFilteredEntidades] = useState([]);
  const [search, setSearch] = useState("");
  const [filterStatus, setFilterStatus] = useState("ativo");
  const [modalCadastro, setModalCadastro] = useState(false);
  const [modalIsOpenConfirmar, setModalIsOpenConfirmar] = useState(false);
  const [descricao, setDescricao] = useState("");
  const [cnpj, setCnpj] = useState("");
  const [cidade, setCidade] = useState("");
  const [ativo,setAtivo] = useState("");
  const [entidadeId,setEntidadeId] = useState();
  const { userId } = useAuth();
  const [lista,setLista] = useState([]);
  const [listaEnt,setListaEnt] = useState([]);
  const [entityToDelete, setEntityToDelete] = useState(null);
 // const navigate = useNavigate();


  const fetchEntidades = async () => {
    try {
      const response = await api.get('/entidades');
      const responseSec = await api.get('/secretarias');
      setEntidades(response.data);
      setSecretarias(responseSec.data);
      setFilteredEntidades(response.data);
    } catch (error) {
      console.error("Erro ao buscar entidades:", error);
    }
  };

  const fetchFiltros = async () => {
    let filtered = entidades;

    if (filterStatus === "ativo") {
      filtered = filtered.filter((entidade) => entidade.ativo === 1);
    } else if (filterStatus === "desativado") {
      filtered = filtered.filter((entidade) => entidade.ativo === 0);
    }

    if (search) {
      filtered = filtered.filter((entidade) =>
        entidade.descricao.toLowerCase().includes(search.toLowerCase())
      );
    }

    setFilteredEntidades(filtered);
  }


  useEffect(() => {
    fetchEntidades();
  }, []);


  useEffect(() => {
    fetchFiltros();
  }, [search, filterStatus, entidades]);

  
  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const handleStatusChange = (e) => {
    setFilterStatus(e.target.value);
  };

  const handleCadastro = () => {
    setModalCadastro(true);
  };

  const handleEdit = (id) => {
    
    setEntidadeId(id);
    const buscaent = entidades.find((entidade) => entidade.id === id);
    if (buscaent) {
      setDescricao(buscaent.descricao);
      setCnpj(buscaent.cnpj);
      setCidade(buscaent.cidade);
      setAtivo(buscaent.ativo);
    }
    const buscasec = secretarias.filter((secretaria) => secretaria.entidade_id === id);
    setLista(buscasec);
    setModalCadastro(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {

      const data = new Date().toISOString();

      const statusAtivo = ativo ? '1' : '0';

      const novaEntidade = {
        descricao: descricao.toUpperCase(),
        cnpj: cnpj.toUpperCase(),
        cidade: cidade.toUpperCase(),
        ativo: statusAtivo,
        criado_por: parseInt(userId, 10),
        criado_em: data,
      };

      const alteraEntidade = {
        id:entidadeId,
        descricao: descricao.toUpperCase(),
        cnpj: cnpj.toUpperCase(),
        cidade: cidade.toUpperCase(),
        ativo: statusAtivo, 
        alterado_por: parseInt(userId, 10), 
        alterado_em: data,
      };

      if (entidadeId) {
        await api.put('/entidades',alteraEntidade);
        Messages.success('Alterado com sucesso!')
      } else {
        await api.post("/entidades",novaEntidade);
        Messages.success('Salvo!')
      } 

      closeModal();
      fetchEntidades();

    } catch (error) {
      console.error("Erro ao cadastrar/atualizar entidade:", error);
    }
    
  };


  const closeModal = () => {
    setDescricao();
    setCnpj();
    setCidade();
    setAtivo();
    setEntidadeId();
    setModalCadastro(false);
    setModalIsOpenConfirmar(false);
  };






  

  const handleDelete = (id) => {
    setEntityToDelete(id);
    setModalIsOpenConfirmar(true);
  };

  const confirmDelete = async () => {
    try {
      await api.delete('/entidades',{data: { id: entityToDelete }});
      const updatedEntidades = entidades.filter((entidade) => entidade.id !== entityToDelete);
      setEntidades(updatedEntidades);
      setFilteredEntidades(updatedEntidades);
      setModalIsOpenConfirmar(false);
      setEntityToDelete(null);
      Messages.success('Excluido com Sucesso!')
    
    } catch (error) {
      console.error("Erro ao excluir entidade:", error);
    }
  };

 



  


  
  return (
    <div className="container">
      <div className="title">Entidades</div>
      <div className="form-group">
      <div className="col">
          <button type="button" className="button" onClick={handleCadastro}>Novo Cadastro</button>
      </div>
      </div>
      <div className="form-group">
        <div className="col">
        <input
          type="text"
          placeholder="Pesquisa"
          value={search}
          onChange={handleSearch}
        />
        </div>
        <div className="col">
        <select className="select" onChange={handleStatusChange} value={filterStatus}>
          <option value="todos">Todos</option>
          <option value="ativo">Ativo</option>
          <option value="desativado">Desativado</option>
        </select>
        </div>
       
      </div>
      <div className="tabela-title">
        <p>Lista de Entidades</p>
      </div>
      <div className="col">
      <div className="tabela">
        <ul>
          {filteredEntidades.length === 0 ? (
            <p>Nenhuma entidade encontrada.</p>
          ) : (
            filteredEntidades.map((entidade, index) => (
              <li key={index}>
                <span onClick={() => handleEdit(entidade.id)}>{entidade.descricao}</span>
                <MdDelete className="icon" onClick={() => handleDelete(entidade.id)} />
              </li>
            ))
          )}
        </ul>
        </div>
      </div>  

      <ModalForm 
        isOpen={modalCadastro}
      >
      <form onSubmit={handleSubmit} className="form">
        <div className="form-group">
          <div className="col">
            <label>Descricao</label>
            <input
              name="descricao"
              type="text"
              value={descricao}
              onChange={(e) => setDescricao(e.target.value)}
              style={{width:400}}
            />
          </div>
         
          <div className="col">
          <label>CNPJ</label>
          <input
            type="text"
            value={cnpj}
            onChange={(e) => setCnpj(e.target.value)}
            maxLength={14}
            style={{width:250}}
          />
          </div>
          <div className="col">
            <label>Cidade</label>
            <input
            name="cidade"
            type="text"
            value={cidade}
            onChange={(e) => setCidade(e.target.value)}
            style={{width: 250}}
          />
          </div>
          <div className="col" >
            <label htmlFor="ativo">Ativo?</label>
            <div className="checkbox">
              <input
                id="ativo"
                name="ativo"
                type="checkbox"
                checked={ativo}
                onChange={(e) => setAtivo(e.target.checked ? 1 : 0)}
              />
            </div>
          </div>
        </div>
        {entidadeId ? 
        <div className="tabela-title"><span>Lista de secretarias</span></div>
        :""
        }
         {entidadeId ?
        <div className="form-group">
          <div className="col" style={{height: 300}}>
            <div className="tabela">
            <ul>
              {lista.length === 0 ? (
                <p>Nenhuma secretaria encontrada.</p>
              ) : (
                lista.map((secretarias, index) => (
                  <li key={index}>
                    <span onClick={() => handleEdit(secretarias)}>{secretarias.descricao}</span>
                    <MdDelete className="icon" onClick={() => handleDelete(secretarias.id)} />
                  </li>
                ))
              )}
            </ul>
            </div>
          </div>
        </div>:""
        }
        <div className="col" style={{marginTop:20,display:"flex",justifyContent:"left"}}>
          <button type="submit">{entidadeId ? "Salvar Alterações" : "Cadastrar Entidade"}</button>
          <button type="submit" onClick={closeModal} style={{background:"red", marginLeft:50}}>Cancelar</button>
        </div>
      </form>
      </ModalForm>

      <ModalConfirmar isOpen={modalIsOpenConfirmar} onClose={closeModal} onConfirm={confirmDelete}/>
      

    </div>
  );
};

export default Cadastros;
