import React from 'react';
import '../../css/ModalForm.css'; 

const Modal = ({ isOpen, children }) => {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay-form">
      <div className="modal-content-form">
        <div className="modal-body-form">
        {children}
        </div>
      </div>
    </div>
  );
};

export default Modal;
