import Swal from "sweetalert2";
class Messages { 
    static confirm (){
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!"
          }).then((result) => {
            if (result.isConfirmed) {
              Swal.fire({
                title: "Deleted!",
                text: "Your file has been deleted.",
                icon: "success"
              });
            }
          });
    }

    static success (text){
        Swal.fire({
            icon: "success",
            title: "Sucesso",
            text: text,
          });
    }

    static error (text){
        Swal.fire({
            icon: "error",
            title: "Oops...",
            text: text
          });
    }
}

export default Messages;