import React, { useState, useEffect } from "react";
import axios from "axios";
import Modal from "react-modal";
import { FaEdit, FaTrash } from "react-icons/fa"; // Ícones de editar e excluir

Modal.setAppElement("#root"); // Garante que o modal seja acessível

const Estoque = () => {
  const [tipoProduto, setTipoProduto] = useState("");
  const [mac, setMac] = useState("");
  const [serial, setSerial] = useState("");
  const [quantidade, setQuantidade] = useState(0);
  const [produtos, setProdutos] = useState([]);
  const [filteredProdutos, setFilteredProdutos] = useState([]);
  const [search, setSearch] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [editingProduto, setEditingProduto] = useState(null);

  useEffect(() => {
    const fetchProdutos = async () => {
      try {
        const response = await axios.get("http://localhost:5000/estoque");
        setProdutos(response.data);
        setFilteredProdutos(response.data);
      } catch (error) {
        console.error("Erro ao buscar produtos:", error);
      }
    };

    fetchProdutos();
  }, []);

  const handleSearch = (e) => {
    const value = e.target.value;
    setSearch(value);

    setFilteredProdutos(
      produtos.filter(
        (produto) =>
          produto.tipo.toLowerCase() === "relogio" &&
          produto.mac &&
          produto.mac.toLowerCase().includes(value.toLowerCase())
      )
    );
  };

  const handleTipoProdutoChange = (e) => {
    setTipoProduto(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      let novoProduto = {};
      if (tipoProduto === "relogio") {
        novoProduto = {
          tipo_produto: "relogio",
          mac: mac,
          serial: serial,
          quantidade: 1,
        };
      } else if (tipoProduto === "fonte") {
        novoProduto = {
          tipo_produto: "fonte",
          mac: "0",
          serial: "0",
          quantidade: quantidade,
        };
      } else if (tipoProduto === "bateria") {
        novoProduto = {
          tipo_produto: "bateria",
          mac: "0",
          serial: "0",
          quantidade: quantidade,
        };
      }

      const response = await axios.post(
        "http://localhost:5000/estoque",
        novoProduto
      );
      const updatedProdutos = [...produtos, response.data];
      setProdutos(updatedProdutos); // Atualizar lista de produtos
      setFilteredProdutos(updatedProdutos); // Atualizar lista filtrada

      // Limpar campos após cadastro
      setTipoProduto("");
      setMac("");
      setSerial("");
      setQuantidade(0);
      setModalIsOpen(false);
      alert("Cadastrado com sucesso!");
    } catch (error) {
      console.error("Erro ao cadastrar produto:", error);
    }
  };

  const handleEdit = (produto) => {
    setTipoProduto(produto.tipo_produto);
    setMac(produto.mac || "");
    setSerial(produto.serial || "");
    setQuantidade(produto.quantidade || 0);
    setEditingProduto(produto);
    setModalIsOpen(true);
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`http://localhost:5000/estoque/${id}`);
      const updatedProdutos = produtos.filter((produto) => produto.id !== id);
      setProdutos(updatedProdutos);
      setFilteredProdutos(updatedProdutos);
      alert("Excluído com sucesso!");
    } catch (error) {
      console.error("Erro ao excluir produto:", error);
    }
  };

  const handleModalSubmit = async (e) => {
    e.preventDefault();
    if (editingProduto) {
      try {
        const updatedProduto = {
          ...editingProduto,
          tipo_produto: tipoProduto,
          mac: mac,
          serial: serial,
          quantidade: quantidade,
        };
        await axios.put(
          `http://localhost:5000/estoque/${editingProduto.id}`,
          updatedProduto
        );
        const updatedProdutos = produtos.map((produto) =>
          produto.id === editingProduto.id ? updatedProduto : produto
        );
        setProdutos(updatedProdutos);
        setFilteredProdutos(updatedProdutos);
        setModalIsOpen(false);
        alert("Alterado com sucesso!");
      } catch (error) {
        console.error("Erro ao alterar produto:", error);
      }
    } else {
      handleSubmit(e);
    }
  };

  const openModalForNewProduct = () => {
    setTipoProduto("");
    setMac("");
    setSerial("");
    setQuantidade(0);
    setEditingProduto(null);
    setModalIsOpen(true);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleSearch(e);
    }
  };

  return (
    <div className="estoque-container">
      <h2>Estoque</h2>
      <div className="estoque-search">
        <input
          type="text"
          placeholder="Buscar por MAC"
          value={search}
          onChange={handleSearch}
          onKeyDown={handleKeyDown}
        />
        <button onClick={openModalForNewProduct}>Adicionar Produto</button>
      </div>
      <div className="estoque-lista">
        <h3>Lista de Produtos</h3>
        <ul>
          {filteredProdutos.map((produto, index) => (
            <li key={index}>
              <div className="estoque-info">{produto.mac}</div>
              <div className="estoque-actions">
                <FaEdit className="icon" onClick={() => handleEdit(produto)} />
                <FaTrash
                  className="icon"
                  onClick={() => handleDelete(produto.id)}
                />
              </div>
            </li>
          ))}
        </ul>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        overlayClassName="modal-overlay"
        className="modal-ent"
        contentLabel="Modal de Cadastro"
      >
        <h2>{editingProduto ? "Editar Produto" : "Cadastrar Produto"}</h2>
        <form className="cadastro-entidades-form" onSubmit={handleModalSubmit}>
          <select value={tipoProduto} onChange={handleTipoProdutoChange}>
            <option value="">Selecione o tipo de produto</option>
            <option value="relogio">Relógio</option>
            <option value="fonte">Fonte</option>
            <option value="bateria">Bateria</option>
          </select>
          {tipoProduto === "relogio" && (
            <>
              <input
                type="text"
                placeholder="MAC"
                value={mac}
                onChange={(e) => setMac(e.target.value)}
              />
              <input
                type="text"
                placeholder="Serial"
                value={serial}
                onChange={(e) => setSerial(e.target.value)}
              />
            </>
          )}
          {tipoProduto === "fonte" && (
            <input
              type="number"
              placeholder="Quantidade"
              value={quantidade}
              onChange={(e) => setQuantidade(e.target.value)}
            />
          )}
          {tipoProduto === "bateria" && (
            <input
              type="number"
              placeholder="Quantidade"
              value={quantidade}
              onChange={(e) => setQuantidade(parseInt(e.target.value))}
            />
          )}
          <button type="submit">
            {editingProduto ? "Salvar Alterações" : "Cadastrar Produto"}
          </button>
        </form>
      </Modal>
    </div>
  );
};

export default Estoque;
