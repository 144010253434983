import React from 'react';
import '../../css/ModalConfirma.css'; // Importa o CSS

const Modal = ({ isOpen , onConfirm, onClose}) => {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay-confirma">
      <div className="modal-content-confirma">
        <div className="modal-header-confirma">Deseja realmente excluir?</div>
        <div className="modal-body-confirma">
        </div>
        <div className="modal-footer-confirma">
          <button className="modal-button-confirma" onClick={onConfirm}>Confirmar</button>
          <button className="modal-button-cancela" onClick={onClose}>Cancelar</button>
        </div>
      </div>
    </div>
  );
};

export default Modal;