
import { useAuth } from "./AuthContext"; 


const Header = () => {
  const { userN } = useAuth();


  // Função para capitalizar a primeira letra
const capitalizeFirstLetter = (string) => {
  if (!string) return '';
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};

const getInitial = (string) => {
  if (!string) return '';
  return string.charAt(0).toUpperCase();
};

  return (
    <div className="header-container">
      <div className="logo">
       <span>iControle</span>
      </div>
      <div className="user-info">
        <span className="box-login"><span className="imagem-login">{getInitial(userN)}</span> <span>{capitalizeFirstLetter(userN) || "Usuário"}</span> </span> {/* Exibe o username */}
      </div>
    </div>
  );
};

export default Header;
