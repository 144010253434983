import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "../services/AuthContext";
import axios from "axios";

const NovaSecretaria = () => {
  const [descricao, setDescricao] = useState("");
  const [contrato, setContrato] = useState("");
  const [rua, setRua] = useState("");
  const [numero, setNumero] = useState("");
  const [bairro, setBairro] = useState("");
  const [complemento, setComplemento] = useState("");
  const [ativo, setAtivo] = useState(true); // Checkbox inicializado como true
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const entidadeId = queryParams.get("entidadeId"); // Obtém o ID da entidade da URL
  const secretariaId = queryParams.get("id"); // Obtém o ID da secretaria para edição, se existir
  const { userId } = useAuth(); // Obtém o userId do contexto

  useEffect(() => {
    if (secretariaId) {
      const fetchSecretaria = async () => {
        try {
          const response = await axios.get(`http://localhost:5000/secretarias/${secretariaId}`);
          const secretaria = response.data;
          setDescricao(secretaria.descricao);
          setContrato(secretaria.contrato);
          setRua(secretaria.rua);
          setNumero(secretaria.numero);
          setBairro(secretaria.bairro);
          setComplemento(secretaria.complemento);
          setAtivo(secretaria.ativo === 1);
        } catch (error) {
          console.error("Erro ao buscar secretaria:", error);
        }
      };

      fetchSecretaria();
    }
  }, [secretariaId]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const data = new Date().toISOString();

      const novaSecretaria = {
        descricao: descricao.toUpperCase(),
        entidade_id: parseInt(entidadeId, 10), // ID da entidade associado
        contrato: contrato.toUpperCase(),
        rua: rua.toUpperCase(),
        numero: numero.toUpperCase(),
        bairro: bairro.toUpperCase(),
        complemento: complemento.toUpperCase(),
        ativo: ativo ? 1 : 0,
        criado_por: parseInt(userId, 10),
        criado_em: data,
      };

      const alteraSecretaria = {
        descricao: descricao.toUpperCase(),
        entidade_id: parseInt(entidadeId, 10),
        contrato: contrato.toUpperCase(),
        rua: rua.toUpperCase(),
        numero: numero.toUpperCase(),
        bairro: bairro.toUpperCase(),
        complemento: complemento.toUpperCase(),
        ativo: ativo ? 1 : 0,
        alterado_por: parseInt(userId, 10),
        alterado_em: data,
      };

      if (secretariaId) {
        await axios.put(`http://localhost:5000/secretarias/${secretariaId}`, alteraSecretaria);
      } else {
        await axios.post("http://localhost:5000/secretarias", novaSecretaria);
      }

      navigate(`/entidades?id=${entidadeId}`); // Redireciona para a lista de secretarias associadas à entidade
    } catch (error) {
      console.error("Erro ao cadastrar/atualizar secretaria:", error);
    }
  };

  return (
    <div className="nova-secretaria-container">
      <div className="cadastro-header">
        {secretariaId ? "Editar Secretaria" : "Nova Secretaria"}
      </div>
      <form onSubmit={handleSubmit} className="nova-secretaria-form">
        <div className="div_imput">
          <input
            name="descricao"
            type="text"
            placeholder="Descrição"
            value={descricao}
            onChange={(e) => setDescricao(e.target.value)}
            required
          />
          <input
            name="contrato"
            type="text"
            placeholder="Contrato"
            value={contrato}
            onChange={(e) => setContrato(e.target.value)}
            required
          />
        </div>
        <div className="div_imput">
          <input
            name="rua"
            type="text"
            placeholder="Rua"
            value={rua}
            onChange={(e) => setRua(e.target.value)}
            required
          />
          <input
            name="numero"
            type="text"
            placeholder="Número"
            value={numero}
            onChange={(e) => setNumero(e.target.value)}
            required
          />
        </div>
        <div className="div_imput">
          <input
            name="bairro"
            type="text"
            placeholder="Bairro"
            value={bairro}
            onChange={(e) => setBairro(e.target.value)}
            required
          />
          <input
            name="complemento"
            type="text"
            placeholder="Complemento"
            value={complemento}
            onChange={(e) => setComplemento(e.target.value)}
          />
        </div>
        <div className="custom-checkbox">
          <input
            id="ativo"
            name="ativo"
            type="checkbox"
            checked={ativo}
            onChange={(e) => setAtivo(e.target.checked)}
          />
          <label htmlFor="ativo">Ativo</label>
        </div>
        <button type="submit">{secretariaId ? "Salvar Alterações" : "Cadastrar Secretaria"}</button>
      </form>
    </div>
  );
};

export default NovaSecretaria;
