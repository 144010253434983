// Sidebar.js

import React from "react";
import { Link } from "react-router-dom";

function Sidebar() {
  
  return (
    <div className="sidebar">
      <nav>
        <ul>
          <li>
            <Link to="/dashboard">Dashboard</Link>
          </li>
          <li>
            <Link to="/entidades">Entidades</Link>
          </li>
          <li>
            <Link to="/estoque">Estoque</Link>
          </li>
          <li>
            <Link to="/ordem-servicos">Ordem de Serviços</Link>
          </li>
          <li>
            <Link to="/relatorios">Relatórios</Link>
          </li>
          <li>
            <Link to="/configuracoes">Configurações</Link>
          </li>
        </ul>
      </nav>
    </div>
  );
}

export default Sidebar;
